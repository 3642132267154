<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getTendersRegistry.name"
                    :avatarData="getTendersRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                         :title="getTendersRegistry.name"
                    :items="getTendersTable.items"
                    :headers="headers"
                    :length="getTendersTable.pages"
                    :page="getTendersTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getTendersSearchFields.searchFields"
                    :loading="getTendersTable.loading"
                >
                    <Columns slot="columns" :items="getTendersTable.items" />
                </Table>
                <Modal
                    :title="$t('tenders:addingTender')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addTender()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('tenders:addtender') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/Tenders/Table/Colums.vue'
import AEContent from './../../../components/Registries/Tenders/Modal/Content.vue'
import Buttons from './../../../components/Registries/Tenders/Modal/Buttons.vue'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('tenders:index'),
                    value: 'index',
                    width: '8%',
                    showSearch: true,
                },
                {
                    text: this.$t('tenders:contact'),
                    value: 'contractor.name',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('tenders:tenderNo'),
                    value: 'tenderNo',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('tenders:createdBy'),
                    value: 'createdBy.name join createdBy.lastname',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('tenders:deadline'),
                    value: 'createdAt',
                    width: '15%',
                },
                {
                    text: this.$t('tenders:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getTendersRegistry',
            'getTendersTable',
            'getTendersSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchTendersTable', 'fetchUsers']),
        ...mapMutations([
            'clearTendersModal',
            'clearTendersErrors',
            'setTendersTable',
            'clearTendersTable',
            'setTendersSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            this.open = false
        },
        async addTender() {
            this.open = true
            this.clearTendersErrors()
            this.clearTendersModal()
        },
        setSearchFields(searchFields) {
            this.setTendersSearch(searchFields)
        },
        setSearch(search) {
            this.setTendersTable({ search, page: 1 })
            this.fetchTendersTable()
        },
        setPage(page) {
            this.setTendersTable({ page })
            this.fetchTendersTable()
        },
        setSort(sort) {
            this.setTendersTable({ sort })
            this.fetchTendersTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearTendersTable()
            this.fetchTendersTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/tenders/:id')
            store.commit('clearTendersTable')
        store.dispatch('fetchTendersTable').then(() => next())
    },
}
</script>
